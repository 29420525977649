import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import Cloudflare from '../../containers/Services/Cloudflare';

const cloudflareData = {
  title: 'Cloudflare Professional Services',
  subTitle: 'Comprehensive Cloudflare Cloud Solutions Tailored for Your Business',
  description:
    'Introduction At Tech Holding, we are proud to partner with Cloudflare, a global leader in web security and performance solutions. Leveraging this powerful partnership, we offer customized Managed Security Service Provider (MSSP) solutions designed to protect, accelerate, and streamline your digital infrastructure. Our focus areas include:',
  subDescription:
    'Explore how our integrated Cloudflare solutions can transform your business operations, ensuring they are more secure, efficient, and ready for the future.',
  seoParams: {
    ogImagePath: '/og-images/cloudflare.png',
    ogTitle: 'Cloudflare Professional Services',
    ogDesc:
      'We are proud to partner with Cloudflare, a global leader in web security and performance solutions.',
    ogPath: '/services/cloudflare',
  },
  focusAreas: [
    {
      label: 'Application Security',
      link: '/services/cloudflare/appsec',
    },
    {
      label: 'Application Performance',
      link: '/services/cloudflare/performance',
    },
    {
      label: 'Secure Access Service Edge (SASE)',
      link: '/services/cloudflare/sase',
    },
  ],
  sections: [
    {
      slug: 'appsec',
      heading: 'Application Security',
      subheading: 'Protect Your Digital Frontiers',
      description:
        'Our advanced application security services harness the power of Cloudflare to offer:',
      columns: [
        {
          items: [
            {
              title: 'DDoS Protection',
              content:
                'Guard against the largest and most sophisticated attacks to ensure uninterrupted service.',
            },
            {
              title: 'Web Application Firewall (WAF)',
              content:
                'Shield your applications from malicious threats and attacks with Cloudflare’s adaptive WAF.',
            },
          ],
        },
        {
          items: [
            {
              title: 'Bot Management',
              content:
                'Protect against automated threats with intelligent bot management that differentiates between good and bad traffic.',
            },
            {
              title: 'Turnstile',
              content:
                'Enhance user interactions by replacing traditional CAPTCHAs with a frictionless security solution that doesn’t compromise on protection.',
            },
          ],
        },
      ],
    },
    {
      slug: 'performance',
      heading: 'Application Performance',
      subheading: 'Accelerate and Stabilize Your Digital Services',
      description:
        'With Tech Holding and Cloudflare, enhance your application performance through:',
      columns: [
        {
          items: [
            {
              title: 'Content Delivery Network (CDN)',
              content:
                'Distribute your content around the world, so it’s closer to your users, speeding up load times.',
            },
          ],
        },
        {
          items: [
            {
              title: 'DNS Resolution',
              content:
                'Utilize Cloudflare’s lightning-fast DNS to improve the responsiveness of your applications.',
            },
          ],
        },
      ],
    },
    {
      slug: 'sase',
      heading: 'Secure Access Service Edge (SASE)',
      subheading: 'Streamline Network Security and Access',
      description: 'Our SASE solutions integrate seamlessly with your IT architecture, offering:',
      columns: [
        {
          items: [
            {
              title: 'Zero Trust Network Access (ZTNA)',
              content:
                'Provide secure and conditional access to network applications based on user identity and context.',
            },
          ],
        },
        {
          items: [
            {
              title: 'Email Security',
              content: 'Protect against phishing, malware, and other email-based threats.',
            },
          ],
        },
      ],
    },
  ],
  whyPartner: {
    heading: 'Why Partner with Tech Holding?',
    subheading: 'Expertise, Support, and Innovation',
    description: 'Choose Tech Holding as your Cloudflare MSSP to benefit from:',
    columns: [
      {
        items: [
          {
            title: 'Tailored Solutions',
            content: 'Custom configurations that address your specific business challenges.',
          },
          {
            title: 'Expert Support',
            content:
              'Access to our team of experts who understand your needs and are ready to assist 24/7.',
          },
        ],
      },
      {
        items: [
          {
            title: 'Strategic Growth',
            content:
              'Innovative solutions that scale with your business, helping you stay ahead in a competitive market.',
          },
        ],
      },
    ],
  },
  callToAction: {
    heading: 'Get Started with Tech Holding Today',
    description:
      'Ready to enhance your business with our Cloudflare solutions? Contact us to discuss your needs or schedule a consultation. Our experts are here to help you navigate your digital transformation journey with confidence.',
  },
};

const propTypes = {
  data: PropTypes.shape({
    contentfulCompanyQuote: PropTypes.shape({
      footerDescription: PropTypes.shape({
        json: PropTypes.shape({}).isRequired,
      }).isRequired,
    }),
  }).isRequired,
};

const CloudflarePage = ({ data }) => (
  <Cloudflare details={cloudflareData} companyQuote={data.contentfulCompanyQuote} />
);

CloudflarePage.propTypes = propTypes;

export default CloudflarePage;

export const query = graphql`
  query {
    contentfulCompanyQuote(label: { eq: "servicesAws" }) {
      footerDescription {
        json
      }
    }
  }
`;
